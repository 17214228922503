
import { defineComponent, ref } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import IconSearch from '@/components/Icon/IconSearch.vue';
import constRouter from '@/constants/constRouter';
import { listBank } from '@/apis/ApiBank';
import { useStore, mapGetters, mapMutations } from 'vuex';
export default defineComponent({
  components: {
    DefaultLayout,
    IconSearch,
  },
  setup() {
    const store = useStore();
    const loading = ref(false);
    const fetchBankList = () => {
      loading.value = true;
      listBank()
        .then((res) => {
          store.commit('bank/setBankList', res.data);
          loading.value = false;
        })
        .catch((err) => {
          console.log(err);
          loading.value = false;
        });
    };
    fetchBankList();
    return {
      loading,
    };
  },
  computed: {
    ...mapGetters({
      data: 'bank/getBankList',
    }),
  },
  methods: {
    ...mapMutations({
      setBankChooseLink: 'bank/setBankChooseLink',
    }),
    handleClick(bank: any) {
      this.setBankChooseLink(bank);
      this.$router.push({
        name: constRouter.BANK_ACCOUNT_INFO.name,
      });
    },
  },
});
